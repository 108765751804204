.projectContainer {
    margin: 50px 50px 20px 50px;
    font-family: 'Roboto Condensed', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 630px) {
    .projectContainer{
    margin: 50px 10px 20px 10px;
    }
}

.projectHeading {
    margin-bottom: 0;
}

.titleHead {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imageDescription {
    margin-top: 0;
    width: 600px;
    height: auto;
    display: flex;
    align-items: center;
    text-align: justify;
}

@media screen and (max-width: 630px) {
  .imageDescription {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%
  } 
  .description {
      margin-bottom: 10px;
  } 
}

.image {
    margin-right: 20px;
    width: 200px;
}

.projectHR{
    border: 1px solid lightgrey;
    margin: 10px 0 0 0;
    width: 100%
}
