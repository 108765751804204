.contactContainer {
    margin: 50px 30px 0 30px;
    font-family: 'Roboto Condensed', sans-serif;
    /* font-family: 'Raleway', sans-serif; */
    display: flex;
    flex-direction: column;
    align-items: center;

}

.contactText {
    width: 50%
}