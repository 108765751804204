.blogContainer {
    margin: 0px 50px 0 50px;
    font-family: 'Roboto', sans-serif;
    width: 60%;
    line-height: 1.5rem;
}

@media screen and (max-width: 830px){
    .blogContainer {
           margin: 0px 10px 0 10px;
           width: 90%;
        }
    }

.blogHeading {
    margin-bottom: 0;
    border: 2px solid lightgrey;
    padding: 10px;
}

@media screen and (max-width: 385px){
    .blogHeading {
            font-size: 2rem;
            font-weight: 400;
            margin-bottom: 0;
        }
    }

@media screen and (max-width: 695px){
    .blogHeading {
        font-size: 2.1rem;
        font-weight: 400;
    }
}


.blogTitle {
    margin: 15px 0 0 0;
}

.blogDate{
    margin: 5px 0 2px 0;
    color:grey;
    font-size: 0.7rem;

}

.blogTitleDate {
    display:flex; 
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start
}

.blogText {
    display: flex;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%
}

.blogTextPlusLink{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.linkURL {
    margin-top: 0;
    display: flex;
    justify-content: flex-start;
}

.textImage {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@media screen and (max-width: 800px){
    .textImage {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
}

.blogImage {
    width: 200px;
    height: auto;
    margin-right: 25px;
}

.blogDivider {
    margin:15px 0 0 0
}