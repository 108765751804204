.teamContainer {
    margin: 50px 20px 20px 20px;
    font-family: 'Roboto Condensed', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.teamHeading {
    margin-bottom: 0
}

.teamMemberForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
} 

.teamMemberTextArea {
    margin-bottom: 20px;
}

.teamMemberInput {
    height: 1.5rem;
    width: 400px;
    border: 1px black solid;
    padding: 5px;
    margin-bottom: 25px;
    
}

.titleHead {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.person {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.portrait {
    margin-top: 0;
    width: 200px;
    height: auto;
    display: flex;
    align-items: center;
}

.landscape {
    margin-top: 0;
    height: 250px;
    width: auto; 
    display: flex;
    align-items: center;
}


.contactDetails {
    margin-left: 10px;
}

.teamMemberButton{
    width: 250px;
    height: 2rem;
    background-color:steelblue;
    color: white;
    border: 1px solid black;
    margin-top: 20px
}

.teamMemberHR{
    border: 1px solid lightgrey;
    margin: 10px 0 0 0;
    width: 300px
}
