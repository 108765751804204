.homeContainer {
  margin: 50px 0px 0 0px;
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ;
}

.titles {
  background-image: url("/Website.png");
  background-size: cover;
  width: 100%;
  background-position: center;
}

.bannerTitle {
  font-weight: 400;
  margin: 20px 0 0 0;
}

.bannerSubheading {
  font-weight: 400;
  margin: 5px 0 10px 0;
}

.bannerMinorheading {
  margin: 10px 0 20px 0;
}

@media (max-width: 830px) {
  .bannerTitle[style] {
    font-size: 4rem !important;
    margin-bottom: 0 !important;
  }
  .bannerSubheading[style] {
    font-size: 2rem;
  }
  .bannerMinorheading[style] {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 650px) {
  .bannerTitle[style] {
    font-size: 2.1rem !important;
    font-weight: 400;
  }
  .bannerSubheading[style] {
    font-weight: 300;
    font-size: 1.25rem !important;
  }
  .bannerMinorheading[style] {
    font-weight: 300;
    font-size: 1.1rem !important;
  }
}

@media screen and (max-width: 385px) {
  .bannerTitle[style] {
    font-size: 2rem !important;
    font-weight: 400;
  }
  .bannerSubheading[style] {
    font-weight: 300;
    font-size: 1.2rem !important;
  }
  .bannerMinorheading[style] {
    font-weight: 300;
    font-size: 1rem !important;
  }
}

.labDescription {
  padding: 0px;
  width: 70%;
  margin-bottom: 20px;
  text-align: justify;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  line-height: 1.5rem;
}

@media screen and (max-width: 600px) {
  .labDescription {
    font-size: 0.9rem;
    width: 90%;
  }
}

.labDescriptionText {
  margin: 0;
  color: black;
  font-size: 1.1rem;
  width: 90%;
}

@media screen and (max-width: 600px) {
  .labDescriptionText {
    font-size: 0.9rem;
    width: 100%;
  }
}

.bannerPhoto {
  height: 300px;
  width: auto;
  margin-bottom: 10px;
  border-radius: 8px;
}

@media screen and (max-width: 830px) {
  .bannerPhoto {
    height: 250px;
    width: auto;
  }
}

@media screen and (max-width: 650px) {
  .bannerPhoto {
    height: 200px;
    width: auto;
  }
}

.creditContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 0 20px 10px 0px;
}

.credit {
  font-size: 0.8rem;
  color: rgb(136, 136, 136);
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 695px) {
  .credit {
    font-size: 0.6rem;
    color: rgb(136, 136, 136);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bcl2sitecontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 40px 0;
  line-height: 1.5rem;
}

.bcl2websiteImage {
  width: 200px;
  margin-right: 20px;
}

.bcl2websiteText {
  text-align: justify;
}

@media screen and (max-width: 695px) {
  .bcl2sitecontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: 20px 0;
  }
  .bcl2websiteText {
    text-align: left;
    width: 100%;
    font-size: 0.9rem;
    text-align: justify;
  }
}
