.documentContainer {
    margin: 0 20px 0 20px;
    font-family: 'Roboto Condensed', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media screen and (max-width: 630px){
.documentContainer {
    margin: 0px 5px 0 5px;
    }
}

.docCategoryHeading{
    color:rgb(143, 143, 143);
    /* text-align: left; */
}

.titleHeadDoc {
    margin-top: 50px;
    margin-bottom: 10px;
}

.listContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
}

.display {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
}

.documents {
    display: flex;
    align-items: center;
    margin: 0;
}

.documentLink {
    margin-bottom: 5px
}

.documentItem {
    font-weight: bold;
     margin: 0;
}

.createdName {
    margin-bottom: 5px
}
.docHR {
    margin-top: 8px;
    border: 1px solid lightgrey;
    width:100%
}  

