.linkButton{
    width: 250px;
    height: 2rem;
    background-color:steelblue;
    color: white;
    border: 1px solid black;
    margin: 5px
}

.submittedText{
    color: red
}

.submittedForm{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}