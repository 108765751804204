.addMediaContainer {
    margin: 50px 50px 0 50px;
    font-family: 'Roboto Condensed', sans-serif;
}

.addMediaFormHeading {
    display: flex;
    flex-direction: column;
    align-items:center;
    margin: 30px;
}

.addMediaFormsubHeading{
    text-align: center;
    margin-bottom: 10px;
}

.titleLabel {
    display: flex;
    flex-direction: column;
    align-items:center;
    color: steelblue;
    margin-bottom: 20px;
}

.uploadForm {
        display: flex;
        flex-direction: column;
        align-items:center;
}

.uploadButton {
    width: 100px;
    height: 2rem;
    background-color:steelblue;
    color: white;
    border: 1px solid black;
    margin-bottom: 30px;
    margin-top: 20px;
}

#caret {
    font-size: 1.5rem;
}

.backButton {
    width: 100px;
    height: 2rem;
    background-color:indianred;
    color: white;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadFileInput {
    margin: 20px, 0, 20px, 0
}

label {
    margin-bottom: 10px;
}

hr {
    border: 1px solid lightgrey;
    width:60%
}

.displayPageInput {
    width: 30px;
    height: 1.1rem;
    margin: 0 5px;

}

.pageNumberButton {
    width: 60px;
    height: 1.4rem;
    background-color: lightslategrey;
    border: 1px solid black
}

.errorDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#pdfIconMedia {
    color:red
}

#imageIconMedia {
    color: purple
}

.mediaAddedWarning{
    color:indianred;
    margin-bottom: 0;
}

.altMediaOption {
    margin-top: 40px;
}