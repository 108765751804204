.addPaperManualContainer {
    margin: 50px 50px 0 50px;
    font-family: 'Roboto Condensed', sans-serif;
}

.addPaperManualFormHeading {
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-bottom: 10px;
}

.addPaperManualForm {
    display: flex;
    flex-direction: column;
    align-items: center;
} 

.manualSearchInput {
    height: 1.5rem;
    width: 400px;
    border: 1px black solid;
    margin-bottom: 20px;
    padding: 5px
}

.commentTextArea {
    padding: 5px;
}

.formLabel {
    display: flex;
    justify-content: flex-start;
    width:400px;
    font-weight: bold ;
    margin-bottom: 5px;
} 

.manualSearchButton {
    width: 100px;
    height: 2rem;
    background-color:steelblue;
    color: white;
    border: 1px solid black;
    margin-top: 5px 
}

.typeDropDown {
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
}

.typeLabel {
    font-weight: bold;
    margin-right: 15px;
}