.adminHomeContainer {
    margin: 50px 50px 0 50px;
    font-family: 'Roboto Condensed', sans-serif;
}

.adminHomeHeading {
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-bottom: 10px;
}

.adminHomeHeadingItem {
    margin-bottom:0px;
}

.adminHomeSubHeading {
    display: flex;
    justify-content: flex-start;
}

.adminHomeForm {
    display: flex;
    flex-direction: column;
    align-items:center
}

.adminButton {
    width: 250px;
    height: 2rem;
    background-color:steelblue;
    color: white;
    border: 1px solid black;
    margin: 5px
}

hr {
    width:100%;
    border: 1px solid lightgrey;
}