.addPaperSearchDisplayContainer {
    margin: 50px 50px 0 50px;
    font-family: 'Roboto Condensed', sans-serif;
}

.addPaperSearchFormHeading {
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-bottom: 10px;
}

.addPaperSearchFormHeadingH1 {
    margin-bottom: 5px;
}

.headingPara{
    margin-top: 0;
} 

.eachPaper {
    margin-bottom: 10px;
}

.typeSelector{
    margin-left: 10px
}

.selectButton {
    width: 100px;
    height: 2rem;
    background-color:steelblue;
    color: white;
    border: 1px solid black;
    margin: 5px 0 20px 0; 
}